import axios from 'axios'
import { getLocalStorageValue, removeFromLocalStorage, setLocalStorageValue } from '@/application/utils/localstorage'
import { interceptor } from '@/application/utils/interceptor'

const domain = process.env.VUE_APP_API_PATH
const authRequest = `${domain}api/v1/dashboard/otp/send/`
const codeRequest = `${domain}api/v1/dashboard/otp/verify/`
const passwordRequest = `${domain}api/v1/dashboard/password/verify/`
const signUpRequest = `${domain}api/v1/dashboard/sign-up/`
const addPasswordRequest = `${domain}api/v1/dashboard/add-password/`
const restorePasswordRequest = `${domain}api/v1/dashboard/otp/send/restore/`

export const sendContact = (data) => axios.post(authRequest, data)
export const sendRestorePassword = (data) => axios.post(restorePasswordRequest, data)
export const sendCode = (data) => axios.post(codeRequest, data)
export const sendPassword = (data) => axios.post(passwordRequest, data)
export const sendAddPassword = (data, headers) => axios.post(addPasswordRequest, data, headers)

export const getSignupData = () => {
  const signup_id = getLocalStorageValue('signup_id')
  return interceptor(axios).get(signup_id ? `${signUpRequest}${signup_id}/` : signUpRequest).then((result) => {
    if (!signup_id) {
      setLocalStorageValue('signup_id', result.data.id, 3600 * 24 * 30)
    }
    return { ...result.data }
  })
}

export const setSignupData = (data) => {
  const signup_id = getLocalStorageValue('signup_id')
  const url = signUpRequest + signup_id + '/'
  return interceptor(axios).put(url, data)
}

export const finishSignup = async(signupData) => {
  const signup_id = getLocalStorageValue('signup_id')
  const url = signUpRequest + signup_id + '/'
  const response = await interceptor(axios).post(url, signupData)
  removeFromLocalStorage('sign_up')
  return response
}
